import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDemandControlRequestMutation,
  useLazyViewDemandControlActionByResponseQuery,
  useLazyViewDemandControlResponseQuery,
  usePublishDemandControlActionMutation,
  useUpdateDemandControlRequestMutation,
  useUpdatePowerConsumerInDemandControlActionMutation,
  useUpdateSpaceClusterInDemandControlActionMutation,
} from "../../../../redux/api/usage-guide/usageGuideAPI";
import AddTimeRange, {
  ITimeRangeProps,
  initialTime,
} from "../../../../shared/components/add-time-range/add-time-range";
import AddDemandResponseProgramRequestDTO from "../../../../shared/oversight-core/dtos/request-dtos/add-demand-response-program-request-dto";
import AddDemandControlRequestResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/add-demand-control-request-response-dto";
import UpdateDemandControlRequestResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/update-demand-control-request-response-dto";
import { default as ViewDemandControlActionByResponseDTO } from "../../../../shared/oversight-core/dtos/response-dtos/view-demand-control-action-by-response-dto";
import ViewDemandControlResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/view-demand-control-response-dto";
import { EDemandControlResponseStatus } from "../../../../shared/oversight-core/enums/deman-control-response-status";
import { EDemandControlActionStatus } from "../../../../shared/oversight-core/enums/demand-control-action-status";
import { EDemandControlRequestType } from "../../../../shared/oversight-core/enums/demand-control-request-type";
import { OvstErrorCode } from "../../../../shared/oversight-core/enums/ovst-error-codes";
import { EPowerMeasureType } from "../../../../shared/oversight-core/enums/power-measure-type";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import { IDemandControlAction } from "../../../../shared/oversight-core/interfaces/demand-control-action";
import { IDemandControlResponseView } from "../../../../shared/oversight-core/interfaces/demand-control-response-view";
import { IShallowDemandControlMethodView } from "../../../../shared/oversight-core/interfaces/shallow-demand-control-method-view";
import AppDatePicker from "../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../shared/oversight-core/ui-elements/input/app-input";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { convertIsoStringToTimeList } from "../../../../shared/oversight-core/utils/convert-ISO-string-to-time-list";
import { powerConvertor } from "../../../../shared/oversight-core/utils/power-convertor";
import { noSpecialCharsNoWhitespaceStartEndAndAllowCharacters } from "../../../../shared/oversight-core/utils/regex";
import {
  formatTimeSlots,
  generateTimeRangeList,
} from "../../../../shared/oversight-core/utils/time-utils";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../shared/oversight-core/utils/toast";
import RequestEditConfirmModal from "../../modal/request-edit-confirm-modal/request-edit-confirm-modal";
import ViewUserDevicesModal from "../../modal/view-user-devices-modal/view-user-devices-modal";
import DemandManagementCard from "../demand-management-card/demand-management-card";
import ReductionRequestView from "../reduction-request-view/reduction-request-view";
import styles from "./automated-demand-control-request.module.scss";

interface IFormInput {
  title: string;
  demandRequestType: Option | [];
  power: number | undefined;
  demandControlDate: Date;
}

const defaultFormValues: IFormInput = {
  title: "",
  demandRequestType: [],
  power: undefined,
  demandControlDate: new Date(),
};

const demandRequestTypes: Option[] = [
  {
    value: EDemandControlRequestType.DEMAND_CONTROL_LIMIT,
    label: "Requested demand control limit",
  },
  {
    value: EDemandControlRequestType.POWER_REDUCTION,
    label: "Requested power reduction",
  },
];

const powerMeasureTypes: Option[] = [
  {
    value: EPowerMeasureType.WATT,
    label: "W",
  },
  {
    value: EPowerMeasureType.KILO_WATT,
    label: "kW",
  },
  {
    value: EPowerMeasureType.MEGA_WATT,
    label: "MW",
  },
  {
    value: EPowerMeasureType.GIGA_WATT,
    label: "GW",
  },
];

const AutomatedDemandControlRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;

  const [isShowErrors, setIsShowErrors] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeList, setTimeList] = useState<ITimeRangeProps[]>([
    { ...initialTime },
  ]);
  const [selectedPowerMeasureType, setSelectedPowerMeasureType] =
    useState<Option>({ ...powerMeasureTypes[2] });
  const [isErrors, setIsErrors] = useState(false);
  const [checked, setIsChecked] = useState(false);
  const [drpRequestId, setDrpRequestId] = useState("");
  const [demandControlResponse, setDemandControlResponse] =
    useState<IDemandControlResponseView>();
  const [selectedDemandControllerMethod, setSelectedDemandControllerMethod] =
    useState<IShallowDemandControlMethodView>();
  const [selectedPage, setSelectedPage] = useState(1);
  const [demandControlActions, setDemandControlActions] =
    useState<ViewDemandControlActionByResponseDTO>();
  const [filters, setFilters] = useState({ pageNumber: 0, pageSize: 10 });
  const [totalElements, setTotalElements] = useState(0);
  const [showViewUserDeviceModal, setShowViewUserDeviceModal] = useState(false);
  const [
    viewSpaceClusterInDemandControlAction,
    setViewSpaceClusterInDemandControlAction,
  ] = useState<IDemandControlAction>();
  const [responseLoading, setResponseLoading] = useState(false);
  const [isUpdateDemandControlAction, setIsUpdateDemandControlAction] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isRestValue, setIsResetValue] = useState(false);
  const [updateCurrentStatus, setUpdateCurrentStatus] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [demandControlRequest] = useDemandControlRequestMutation();
  const [updateDemandControlRequest] = useUpdateDemandControlRequestMutation();
  const [publishDemandControlAction] = usePublishDemandControlActionMutation();
  const [
    triggerDemandControlResponse,
    { isFetching: isFetchingDemandControlResponse },
  ] = useLazyViewDemandControlResponseQuery();
  const [
    triggerDemandControlAction,
    { isFetching: isFetchingDemandControlAction },
  ] = useLazyViewDemandControlActionByResponseQuery();
  const [dateTimeError, setDateTimeError] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [updatePowerConsumerInDemandControlAction] =
    useUpdatePowerConsumerInDemandControlActionMutation();
  const [updateSpaceClusterInDemandControlAction] =
    useUpdateSpaceClusterInDemandControlActionMutation();

  const startTime = new Date("2000-01-01T00:00:00");
  const endTime = new Date("2000-01-02T00:00:00");
  const intervalMinutes = 15;

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      trigger(name);
    });

    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  useEffect(() => {
    if (demandControlActions && viewSpaceClusterInDemandControlAction) {
      const found = demandControlActions.affectedSpaceClusters.elements.find(
        (dca) => dca.id === viewSpaceClusterInDemandControlAction.id
      );

      setViewSpaceClusterInDemandControlAction(found);
    }
  }, [demandControlActions, viewSpaceClusterInDemandControlAction]);

  useEffect(() => {
    if (locationState?.demandControl) {
      const powerMeasureTypeValue = powerMeasureTypes.find(
        (option) =>
          option.value ===
          (locationState?.demandControl.demandControlRequestView
            .powerMeasureType as EPowerMeasureType)
      );
      const demandControlRequestType = demandRequestTypes.find(
        (option) =>
          option.value ===
          (locationState?.demandControl.demandControlRequestView
            .demandControlRequestType as EDemandControlRequestType)
      );
      setResponseLoading(
        locationState?.demandControl.status !==
          EDemandControlResponseStatus.COMPLETED
      );
      setValue(
        "title",
        locationState.demandControl.demandControlRequestView.title
      );
      setValue(
        "power",
        powerConvertor(
          locationState.demandControl.demandControlRequestView.power,
          locationState?.demandControl.demandControlRequestView
            .powerMeasureType as EPowerMeasureType
        )
      );
      setSelectedDate(
        new Date(
          locationState?.demandControl.demandControlRequestView.demandControlDate
        )
      );
      setTimeList(
        convertIsoStringToTimeList([
          locationState?.demandControl.demandControlRequestView
            .demandControlPeriod,
        ])
      );
      setDrpRequestId(locationState?.demandControl.demandControlRequestView.id);
      if (powerMeasureTypeValue)
        setSelectedPowerMeasureType(powerMeasureTypeValue);
      if (demandControlRequestType)
        setValue("demandRequestType", demandControlRequestType);
    }
  }, [locationState?.demandControl, setValue, isRestValue]);

  useEffect(() => {
    if (locationState?.demandControl) {
      setDemandControlResponse(locationState?.demandControl);
      setResponseLoading(true);
      setShowEditIcon(true);
    }
  }, [locationState?.demandControl]);

  const onPreSubmit = async () => {
    const isValid = await trigger();

    setIsShowErrors(true);

    if (!isValid || dateTimeError) {
      if (locationState && !isEdit) {
        showErrorMessage(
          "Selected time and date are greater than the current time and date"
        );
      }
      return;
    }

    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (
      timeList.length > 0 &&
      timeList[0].startTime.value &&
      timeList[0].endTime.value
    ) {
      const formattedTimeSlots = formatTimeSlots(selectedDate, timeList)[0];
      const currentTime = moment(moment().startOf("minute").toISOString(true));
      const timeDifference = moment(formattedTimeSlots.fromDate).diff(
        currentTime,
        "minutes"
      );

      if (timeDifference <= 0) {
        setDateTimeError(
          "Please select a time slot that is greater than the current date and time"
        );
        return;
      }

      setDateTimeError("");
    }
  }, [timeList, selectedDate, isEdit, locationState]);

  const onSubmit = async (data: IFormInput) => {
    if (
      selectedPage === 2 &&
      demandControlActions?.demandControlActionId &&
      !isEdit
    ) {
      publishDemandControlAction({
        demandControlActionId: demandControlActions?.demandControlActionId,
      })
        .then(() => {
          showSuccessMessage("Demand Control Action publish successfully");
          navigate(AppRoute.ADMIN_USAGE_GUIDE);
        })
        .catch(() => {
          showErrorMessage("Demand Control Action publish unsuccessfully");
        });
      return;
    }

    if (selectedDemandControllerMethod) {
      setSelectedPage(2);
      return;
    }

    const title = data.title;
    const demandRequestType = (data.demandRequestType as Option)
      .value as EDemandControlRequestType;
    const power = data.power ?? 0;
    const powerMeasureType =
      selectedPowerMeasureType.value as EPowerMeasureType;
    const demandControlDate = moment(selectedDate)
      .startOf("day")
      .toISOString(true);
    const demandControlPeriod = formatTimeSlots(selectedDate, timeList)[0];

    const demandRequestObject: AddDemandResponseProgramRequestDTO = {
      title,
      demandRequestType,
      power,
      powerMeasureType,
      demandControlDate,
      demandControlPeriod,
    };

    if (!isErrors) {
      if ((locationState?.demandControl || showEditIcon) && isEdit) {
        if (
          (demandRequestType !==
            locationState?.demandControl.demandControlRequestView
              .demandControlRequestType ||
            power !==
              locationState.demandControl.demandControlRequestView.power ||
            demandRequestType !==
              locationState?.demandControl.demandControlRequestView
                .demandControlRequestType ||
            data.demandControlDate !==
              new Date(
                locationState?.demandControl.demandControlRequestView.demandControlDate
              ) ||
            demandControlPeriod !==
              locationState?.demandControl.demandControlRequestView
                .demandControlPeriod) &&
          !showConfirmationModal &&
          title === locationState?.demandControl.demandControlRequestView.title
        ) {
          setShowConfirmationModal(true);
          return;
        }

        await updateDemandControlRequest({
          ...demandRequestObject,
          demandControlDate: demandControlPeriod.fromDate,
          id:
            locationState?.demandControl.demandControlRequestView.id ||
            drpRequestId,
        })
          .unwrap()
          .then((res: UpdateDemandControlRequestResponseDTO) => {
            showSuccessMessage("Demand Request Updated Successfully");
            setDrpRequestId(res.drpRequestId);
            setShowConfirmationModal(false);
            setIsEdit(false);
            setUpdateCurrentStatus((ps) => !ps);
          })
          .catch((error) => {
            setShowConfirmationModal(false);
            if (
              error.status === 412 &&
              error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001
            ) {
              showErrorMessage("Demand control request title already in use");
              return;
            }
            showErrorMessage("Demand Request Updated Unsuccessfully");
          });
      } else {
        await demandControlRequest(demandRequestObject)
          .unwrap()
          .then((res: AddDemandControlRequestResponseDTO) => {
            showSuccessMessage("Demand Request Added Successfully");
            setDrpRequestId(res.drpRequestId);
            setShowEditIcon(true);
          })
          .catch((error) => {
            if (
              error.status === 412 &&
              error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001
            ) {
              showErrorMessage("Demand control request title already in use");
              return;
            }
            showErrorMessage("Demand Request Added Unsuccessfully");
          });
      }
    }
  };

  useEffect(() => {
    if (drpRequestId) {
      triggerDemandControlResponse({
        demandControlRequestId: drpRequestId,
      })
        .unwrap()
        .then((res: ViewDemandControlResponseDTO) => {
          setDemandControlResponse(res.demandControlResponseView);
          setResponseLoading(
            res.demandControlResponseView.status !==
              EDemandControlResponseStatus.COMPLETED
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [drpRequestId, updateCurrentStatus, triggerDemandControlResponse]);

  useEffect(() => {
    if (drpRequestId && selectedDemandControllerMethod && selectedPage === 2) {
      triggerDemandControlAction({
        demandControlRequestId: drpRequestId,
        demandControlMethod: selectedDemandControllerMethod.demandControlMethod,
        pageNumber: filters.pageNumber,
        pageSize: filters.pageSize,
      })
        .unwrap()
        .then((res: ViewDemandControlActionByResponseDTO) => {
          setDemandControlActions(res);
          setTotalElements(res.affectedSpaceClusters.totalElements);
          setIsLoading(true);
        })
        .catch(() => {
          setTotalElements(0);
        });
    }
  }, [
    drpRequestId,
    filters.pageNumber,
    filters.pageSize,
    selectedDemandControllerMethod,
    triggerDemandControlAction,
    selectedPage,
    isUpdateDemandControlAction,
  ]);

  const updatePowerConsumer = async (
    demandControlActionId: string,
    spaceClusterId: string,
    powerConsumerId: string
  ) => {
    setIsLoading(false);
    await updatePowerConsumerInDemandControlAction({
      demandControlActionId,
      spaceClusterId,
      powerConsumerId,
    })
      .unwrap()
      .then(() => {
        setIsUpdateDemandControlAction((ps) => !ps);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateSpaceCluster = (
    demandControlActionId: string,
    spaceClusterId: string
  ) => {
    updateSpaceClusterInDemandControlAction({
      demandControlActionId,
      spaceClusterId,
    })
      .then(() => {
        showSuccessMessage(
          `Space Cluster ${
            viewSpaceClusterInDemandControlAction?.isRemoved
              ? `Enable`
              : `Disable`
          } Successfully`
        );
        setShowViewUserDeviceModal(false);
        setIsUpdateDemandControlAction((ps) => !ps);
        setIsLoading(true);
      })
      .catch(() => {
        showErrorMessage(
          `Space Cluster ${
            viewSpaceClusterInDemandControlAction?.isRemoved
              ? `Enable`
              : `Disable`
          } Unsuccessfully`
        );
      });
  };

  return (
    <div className={`container-white position-relative ${styles.overflowY}`}>
      {selectedPage === 1 ? (
        <>
          <Row className="justify-content-between mt-2">
            <Col className="col-12 col-xxl-5">
              <Row>
                <Col>
                  <Row>
                    <Col className="font-size-16 font-weight-500">Request</Col>
                    {showEditIcon && !isEdit && (
                      <Col className="col-auto">
                        <Row>
                          <Col>
                            <MaterialIcon
                              icon={"edit"}
                              size={22}
                              className="cursor-pointer"
                              onClick={() => {
                                setIsEdit(true);
                                setSelectedDemandControllerMethod(undefined);
                                setIsChecked(false);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <AppInput
                      className="mt-4"
                      label="Title"
                      placeholder="Title"
                      name="title"
                      errors={errors}
                      register={register("title", {
                        required: "Please enter a title",
                        pattern: {
                          value:
                            noSpecialCharsNoWhitespaceStartEndAndAllowCharacters,
                          message:
                            "Entered value must start with a alphanumeric character and can contain |, -, _, ' as special characters",
                        },
                      })}
                      disabled={!isEdit && (locationState || showEditIcon)}
                    />
                    <AppSelect
                      className="mt-4"
                      label="Request Type and Amount"
                      placeholder="Select Request Type"
                      defaultValue={""}
                      name="demandRequestType"
                      options={demandRequestTypes}
                      register={register("demandRequestType", {
                        required: "Please select a request type",
                      })}
                      errors={errors}
                      control={control}
                      isRequired
                      disabled={!isEdit && (locationState || showEditIcon)}
                    />
                    <Col className="pe-0">
                      <AppInput
                        className="mt-1"
                        type="number"
                        placeholder="Power Value"
                        name="power"
                        errors={errors}
                        register={register("power", {
                          required: "Please enter power value",
                          min: {
                            value: 0.0001,
                            message: "Please enter a value greater than 0",
                          },
                        })}
                        borderRadiusStyle="Right"
                        disabled={!isEdit && (locationState || showEditIcon)}
                      />
                    </Col>
                    <Col className="ps-0">
                      <AppSelect
                        className="mt-1"
                        selectedValue={{ ...selectedPowerMeasureType }}
                        options={[...powerMeasureTypes]}
                        onChangeOption={(selectedOption) => {
                          setSelectedPowerMeasureType(selectedOption);
                        }}
                        name="powerMeasureType"
                        borderRadiusStyle="Left"
                        id="power-measure-type"
                        disabled={!isEdit && (locationState || showEditIcon)}
                      />
                    </Col>
                    <div className="mt-4">
                      <label>
                        Date<span className={styles.requiredMark}>*</span>
                      </label>
                      <AppDatePicker
                        dateFormat="dd MMMM yyyy"
                        selectedDate={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        isInput={true}
                        disabled={!isEdit && (locationState || showEditIcon)}
                      />
                    </div>
                    <div className="mt-3">
                      <AddTimeRange
                        times={timeList}
                        setTimes={setTimeList}
                        timeOptions={generateTimeRangeList(
                          startTime,
                          endTime,
                          intervalMinutes
                        )}
                        isErrors={isErrors}
                        setIsErrors={setIsErrors}
                        isShowAddTimeSlotButton={false}
                        isShowErrors={isShowErrors}
                        disabled={!isEdit && (locationState || showEditIcon)}
                      />
                    </div>
                    {(isEdit || !locationState) && (
                      <p className="error font-size-12 mt-1">{dateTimeError}</p>
                    )}
                  </Row>
                </Col>
                <Col xs={1} className="d-none d-xxl-block ps-4">
                  <div
                    style={{
                      height: `${
                        demandControlResponse?.demandControlMethods?.length ===
                          3 && `95vh`
                      }`,
                    }}
                    className={styles["vertical-line"]}
                  ></div>
                </Col>
              </Row>
            </Col>
            <Col className="d-block d-xxl-none mt-5 mb-3">
              <div className={styles["horizontal-line"]}></div>
            </Col>
            <Col className="col-12 col-xxl-7 mt-4 mt-xxl-0">
              <Row>
                <Col className="font-size-16 font-weight-500">Response</Col>
              </Row>
              {responseLoading ? (
                <Row className="align-items-center justify-content-start mt-3">
                  <Col className="col-auto pe-0">
                    <SpinnerModal show={responseLoading} withOverlay={false} />
                  </Col>
                  <Col className="text-light font-size-14 font-weight-400">
                    Generating Response Plan
                  </Col>
                </Row>
              ) : (
                <>
                  {demandControlResponse ? (
                    <>
                      <Row className="mt-4">
                        <Col className="col-auto pe-0 font-size-14 text-light font-weight-400">
                          Max Usage Prediction :
                        </Col>
                        <Col className="font-size-14">
                          {powerConvertor(
                            demandControlResponse?.predictedMaximumPowerUsage,
                            EPowerMeasureType.MEGA_WATT
                          ).toFixed(2)}{" "}
                          MW
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col className="col-auto pe-0 font-size-14 text-light font-weight-400">
                          Power Available :
                        </Col>
                        <Col className="font-size-14">
                          {powerConvertor(
                            demandControlResponse?.availablePower,
                            EPowerMeasureType.MEGA_WATT
                          ).toFixed(2)}{" "}
                          MW
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col className="col-auto pe-0 font-size-14 text-light font-weight-400">
                          Reduction Needed :
                        </Col>
                        <Col className="font-size-14">
                          {powerConvertor(
                            demandControlResponse?.reductionNeededPowerUsage,
                            EPowerMeasureType.MEGA_WATT
                          ).toFixed(2)}{" "}
                          MW
                        </Col>
                      </Row>
                      <Row className="my-4">
                        <Col className="font-size-16 font-weight-500">
                          Select Demand Management Method
                        </Col>
                      </Row>
                      {demandControlResponse?.demandControlMethods?.map(
                        (item, index) => {
                          return (
                            <Row className="mt-2" key={index}>
                              <Col>
                                <DemandManagementCard
                                  demandControllerMethod={item}
                                  checked={checked}
                                  onChange={(
                                    value,
                                    selectedDemandControllerMethod
                                  ) => {
                                    if (!isEdit) {
                                      setIsChecked(value);
                                      setSelectedDemandControllerMethod(
                                        selectedDemandControllerMethod
                                      );
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <div className="container-dash mt-3">
                      <Row>
                        <Col className="text-center font-size-14 text-light">
                          Add a request first to automatically generate a
                          response
                        </Col>
                      </Row>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <ReductionRequestView
            onBack={() => setSelectedPage(1)}
            headers={[
              { id: 1, columnName: "Account No" },
              { id: 2, columnName: "Usual Usage" },
              { id: 3, columnName: "Reduced Usage" },
              { id: 4, columnName: "Saving" },
              { id: 5, columnName: "Devices" },
              { id: 6, columnName: "" },
            ]}
            tableData={
              demandControlActions
                ? demandControlActions.affectedSpaceClusters.elements.map(
                    (dca) => {
                      return {
                        data: [
                          <>{dca.serviceProviderAccount.accountNumber}</>,
                          <>
                            {powerConvertor(
                              dca.usualUsage,
                              EPowerMeasureType.KILO_WATT
                            ).toFixed(2)}
                            kW
                          </>,
                          <>
                            {powerConvertor(
                              dca.reducedUsage,
                              EPowerMeasureType.KILO_WATT
                            ).toFixed(2)}
                            kW
                          </>,
                          <>
                            {powerConvertor(
                              dca.reduction,
                              EPowerMeasureType.KILO_WATT
                            ).toFixed(2)}
                            kW
                          </>,
                          <>{dca.devices}</>,
                          <>
                            <MaterialIcon
                              key={`${dca.id}`}
                              icon="visibility"
                              color="#2F2A89"
                              className="cursor-pointer"
                              onClick={() => {
                                setShowViewUserDeviceModal(true);
                                setViewSpaceClusterInDemandControlAction(dca);
                              }}
                              size={15}
                            />
                          </>,
                        ],
                        disable: dca.isRemoved,
                      };
                    }
                  )
                : []
            }
            totalElements={totalElements}
            filters={filters}
            setFilters={setFilters}
            demandControlResponseView={demandControlActions}
          />
        </>
      )}
      <Row className="justify-content-center justify-content-sm-between mt-4 px-2`">
        <Col className="col-auto">
          <AppButton
            onClick={() => {
              if (locationState?.demandControl) {
                if (isEdit) {
                  setIsEdit(false);
                  setIsResetValue((ps) => !ps);
                } else {
                  navigate(-1);
                }
              } else {
                navigate(AppRoute.ADMIN_USAGE_GUIDE);
              }
            }}
            text={
              selectedPage === 1 && !locationState?.demandControl
                ? `Back`
                : isEdit
                ? `Discard`
                : `Cancel`
            }
            variant="transparentWithBorder"
            size="medium"
          />
        </Col>
        <Col className="col-auto">
          <AppButton
            onClick={onPreSubmit}
            isLoading={isFetchingDemandControlResponse}
            text={
              selectedPage === 1
                ? responseLoading || !locationState?.demandControl || isEdit
                  ? `Generate`
                  : `Next`
                : `Confirm`
            }
            variant="blue"
            size="medium"
            id="demand-request"
            disabled={
              !selectedDemandControllerMethod && !isEdit && showEditIcon
            }
          />
        </Col>
      </Row>
      <ViewUserDevicesModal
        show={showViewUserDeviceModal}
        onClose={() => setShowViewUserDeviceModal(false)}
        viewSpaceClusterInDemandControlAction={
          viewSpaceClusterInDemandControlAction
        }
        demandControlActionId={
          demandControlActions?.demandControlActionId || ""
        }
        demandControlActionStatus={EDemandControlActionStatus.NOT_STARTED}
        updatePowerConsumer={updatePowerConsumer}
        updateSpaceCluster={updateSpaceCluster}
      />
      <RequestEditConfirmModal
        show={showConfirmationModal}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={onPreSubmit}
      />
      <SpinnerModal show={isFetchingDemandControlAction && isLoading} />
    </div>
  );
};

export default AutomatedDemandControlRequest;
