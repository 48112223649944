export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+~`\-={}[\]\\|:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~`\-={}[\]\\|:;"'<>,.?/]{6,}$/;

export const mobileNumberRegex =
  /^[0][7][0 || 1 || 2 || 4 || 5 || 6 || 7 || 8][0-9]{7}$/;

export const nicRegex = /^([0-9]{9}[xXvV]|[0-9]{12})$/;

export const numericsOnlyRegex = /^[0-9]*$/;

export const decimalRegex = /^[0-9]*[.]{0,1}[0-9]{0,2}$/;

export const noSpecialCharsNoWhitespaceStartEndRegex =
  /^(?!\s)(?!.*\s{2})[a-zA-Z0-9\s]*\w$/;

export const noSpecialCharsNotAllowOnlyNumbersAndNoWhitespaceStartEndRegex =
  /^(?=.*[a-zA-Z])(?=\S*$).+/;

export const noSpecialCharsNoWhitespaceStartEndAllowHyphenRegex =
  /^(?!\s)(?!.*\s{2})[a-zA-Z0-9\s/-]*\w$/;

export const validateInputTypeNumberRegex = /^\d*\.?\d*$/;

export const noOnlySpecialCharsNoWhitespaceStartEndRegex = /^(?=\S*$)\S+$/;

export const noSpecialCharsNoWhitespaceStartEndAllowCommaRegex =
  /^(?!\s)(?!.*\s{2})[a-zA-Z0-9\s/,|-]*\w$/;

export const noSpecialCharsNoWhitespaceNoNumbersStartEndRegex =
  /^(?!\s)(?!.*\s{2})[a-zA-Z\s]*$/;

export const noSpecialCharsNoWhitespaceStartEndNotAllowCommaAndSingleMarkRegex =
  /^(?!\s)(?!.*\s{2})[a-zA-Z0-9\s/_|-]*\w$/;

export const noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex =
  /^(?!\s)(?!.*\s{2})[a-zA-Z0-9\s/_|'-]*\w$/;

export const noSpecialCharsNoWhitespaceStartEndAndAllowCharacters =
  /^(?!\s)(?!.*\s{2})[a-zA-Z0-9\s|_'-]*\w$/;

export const alphanumericAndSomeSpecialCharactersAndCantStartWithSpecialCharacters =
  /^[a-zA-Z][a-zA-Z0-9\-_|’' ]*[a-zA-Z0-9]$/;

// comma and forward slash
export const alphanumericAndSomeSpecialCharactersAndCantStartWithSpecialCharacters2 =
  /^[a-zA-Z][a-zA-Z0-9\-_|’',/ ]*[a-zA-Z0-9]$/;
export const noSpecialCharsNotAllowOnlyNumbersAnd =
  /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z])[\w\s]+$/;
